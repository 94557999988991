import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2018 Mensajes" />
      <h1>2018 Mensajes:</h1>
      <p>
        Abajo están los mensajes del 2018. Para DESCARGAR un mensaje haga CLIC DERECHO en
        enlace de descarga, y seleccione “Salvar destino como...” (Save Target As...) o
        “Salvar enlace como...” (Save Link como...), dependiendo del navegador que use.
        Para ESCUCHAR, sólo haga clic en el enlace “escuchar”. <br />
      </p>
      <h2>La Naturaleza de la Obra Consumada de Cristo - Jason Henderson</h2>
      <table
        className="sermon_table"
        width="646"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="476">La Naturaleza de la Obra Consumada de Cristo</td>
            <td width="49">
              <div align="center">
                <a href={asset('mensajes/18/Naturaleza_de_la_Obra_Consumada.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a href={asset('mensajes/18/Naturaleza_de_la_Obra_Consumada.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td width="33">
              <div align="center">
                <a href={asset('mensajes/18/Naturaleza_de_la_Obra_Consumada.pdf')}>pdf</a>
              </div>
            </td>
            <td width="48">
              <div align="center">
                <a href={asset('mensajes/18/Obra_Consumada.mp3')}>audio</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Robert Barclay</h2>
      <table
        className="sermon_table"
        width="646"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="476">Esperando en el Señor</td>
            <td width="49">
              <div align="center">
                <a href={asset('publicaciones/barclay/esperando/esperando_v1.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a href={asset('publicaciones/barclay/esperando/esperando_v1.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td width="33">
              <div align="center">
                <a href={asset('publicaciones/barclay/esperando/esperando_v1.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td width="48">
              <div align="center"></div>
            </td>
          </tr>
          <tr>
            <td>Consideraciones sobre el Entretenimiento y la Recreación</td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/barclay/entretenimiento_recreacion/entretenimiento_recreacion.mobi',
                  )}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/barclay/entretenimiento_recreacion/entretenimiento_recreacion.epub',
                  )}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/barclay/entretenimiento_recreacion/entretenimiento_recreacion.pdf',
                  )}
                >
                  pdf
                </a>
              </div>
            </td>
            <td>
              <div align="center"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>El Estado Original y Presente del Hombre - Joseph Phipps</h2>
      <table
        className="sermon_table"
        width="646"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="476">El Estado Original y Presente del Hombre</td>
            <td width="49">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/josephphipps/estado_original_y_presente_del_hombre.mobi',
                  )}
                >
                  kindle
                </a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/josephphipps/estado_original_y_presente_del_hombre.epub',
                  )}
                >
                  epub
                </a>
              </div>
            </td>
            <td width="33">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/josephphipps/estado_original_y_presente_del_hombre.pdf',
                  )}
                >
                  pdf
                </a>
              </div>
            </td>
            <td width="48">
              <div align="center"></div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>La Verdad en Lo Íntimo — Capítulos Individuales</h2>
      <table
        width="641"
        height="187"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="470" height="25">
              Cap. 1 - Introducción
            </td>
            <td width="46">
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/01_Introduccion.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="41">
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/01_Introduccion.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td width="37">
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/01_Introduccion.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/01_Introducción.mp3')}>
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="27">Cap. 2 - La Vida de Stephen Crisp</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/02_Stephen_Crisp.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/02_Stephen_Crisp.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/02_Stephen_Crisp.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/02_Stephen_Crisp.mp3')}>
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="27">Cap. 3 - La Vida de John Richardson</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/03_John_Richardson.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/03_John_Richardson.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/03_John_Richardson.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/03_John_Richardson.mp3">
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="27">Cap. 4 - La Carta de Elizabeth Webb</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/04_Elizasbeth_Webb.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/04_Elizasbeth_Webb.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/04_Elizasbeth_Webb.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/04_Elizabeth_Webb.mp3')}>
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="27">Cap. 5 - El Diario de John Burnyeat</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/05_John_Burnyeat.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/05_John_Burnyeat.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/05_John_Burnyeat.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 6 - La Vida de Joseph Pike</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/06_Joseph_Pike.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/06_Joseph_Pike.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/06_Joseph_Pike.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 7 - El Diario de John Griffith</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/07_John_Griffith.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/07_John_Griffith.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/07_John_Griffith.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 8 - Las Memorias de John Crook</td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/08_John_Crook.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/08_John_Crook.epub')}>
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/verdadenlointimo/08_John_Crook.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 9 - La Vida de Elizabeth Stirredge</td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/09_Elizabeth_Stirredge.mobi">
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/09_Elizabeth_Stirredge.epub">
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/09_Elizabeth_Stirredge.pdf">
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 10 - El Diario de Charles Marshall</td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/10_Charles_Marshall.mobi">
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/10_Charles_Marshall.epub">
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/10_Charles_Marshall.pdf">
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 11 - La Vida de Thomas Story</td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/11_Thomas_Story.mobi">
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/11_Thomas_Story.epub">
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/verdadenlointimo/11_Thomas_Story.pdf">
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <h2>Los Sufrimientos de Catharine Evans y Sarah Cheevers</h2>
      <table
        width="641"
        height="100"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td height="20">
              Un Corto Relato de los Sufrimientos de Catharine Evans y Sarah Cheevers
              <div align="center"></div>
            </td>
            <td width="42">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/Sufrimientos_de_Catharine_Evans_Sarah_Cheevers.mobi',
                  )}
                >
                  kindle
                </a>
              </div>
            </td>
            <td width="44">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/Sufrimientos_de_Catharine_Evans_Sarah_Cheevers.epub',
                  )}
                >
                  epub
                </a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/Sufrimientos_de_Catharine_Evans_Sarah_Cheevers.pdf',
                  )}
                >
                  pdf
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="20">
              Los Sufrimientos de Catharine Evans y Sarah Cheevers - parte 1
              <div align="center"></div>
            </td>
            <td colSpan="3">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/01_Sufrimientos_de_Catherine_Evans_Sarah_Cheevers_hq.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="20">
              Los Sufrimientos de Catharine Evans y Sarah Cheevers - parte 2
              <div align="center"></div>
            </td>
            <td colSpan="3">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/02_Sufrimientos_de_Catherine_Evans_Sarah_Cheevers_hq.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="20">
              Los Sufrimientos de Catharine Evans y Sarah Cheevers - parte 3
              <div align="center"></div>
            </td>
            <td colSpan="3">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/03_Sufrimientos_de_Catherine_Evans_Sarah_Cheevers_hq.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td width="515" height="20">
              Los Sufrimientos de Catharine Evans y Sarah Cheevers - parte 4
              <div align="center"></div>
            </td>
            <td colSpan="3">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/evans-cheevers/04_Sufrimientos_de_Catherine_Evans_Sarah_Cheevers_hq.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Un Camino Simple - por Stephen Crisp</h2>
      <table
        width="641"
        height="20"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="508" height="20">
              Un Camino Simple
              <div align="center"></div>
            </td>
            <td width="49">
              <div align="center">
                <a href={asset('publicaciones/stephencrisp/camino_simple.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="44">
              <div align="center">
                <a href={asset('publicaciones/stephencrisp/camino_simple.epub')}>epub</a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a href={asset('publicaciones/stephencrisp/camino_simple.pdf')}>pdf</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>La Biografía de James Parnell</h2>
      <table
        width="641"
        height="20"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="508" height="20">
              La Biografía de James Parnell
              <div align="center"></div>
            </td>
            <td width="49">
              <div align="center">
                <a href={asset('publicaciones/jamesparnell/james_parnell.mobi')}>
                  kindle
                </a>
              </div>
            </td>
            <td width="44">
              <div align="center">
                <a href={asset('publicaciones/jamesparnell/james_parnell.epub')}>epub</a>
              </div>
            </td>
            <td width="40">
              <div align="center">
                <a href={asset('publicaciones/jamesparnell/james_parnell.pdf')}>pdf</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Los Escritos de Isaac Penington — Volumen 2</h2>
      <table
        width="641"
        height="322"
        border="0"
        cellPadding="0"
        cellSpacing="0"
        className="sermon_table"
      >
        <tbody>
          <tr>
            <td width="470" height="25">
              Cap. 1 - Conocimiento Espiritual
            </td>
            <td width="46">
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap01.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td width="41">
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap01.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td width="37">
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap01.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td width="47">
              <div align="center">
                <a
                  href={asset(
                    'publicaciones/isaacpenington/ip_vol2/IPv2_audiobook/IPv2_cap_01_HQ.mp3',
                  )}
                >
                  audio
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td height="27">
              Cap. 2 - Algunas Piedras de Tropiezo Eliminadas del Camino
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap02.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap02.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap02.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">
              Cap. 3 - Una Defensa de la Verdad Santa y del Pueblo Santo de Dios
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap03.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap03.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap03.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 4 - La Verdad Desnuda</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap04.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap04.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap04.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 5 - Las Experiencias</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap05.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap05.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap05.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 6 - Un Llamado al Verdadero Cristianismo</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap06.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap06.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap06.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 7 - A los Judíos Según la Carne y Según el Espíritu</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap07.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap07.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap07.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 8 - El Testimonio de la Verdad</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap08.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap08.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap08.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">
              Cap. 9 - La Vida e Inmortalidad Sacadas a la Luz por el Evangelio
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap09.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap09.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap09.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 10 - La Ministración del Espíritu y Poder</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap10.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap10.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap10.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 11 - El Bautismo de Cristo</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap11.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap11.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap11.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">
              Cap. 12 - Un Testimonio Adicional Sobre la Obra de Dios en Nuestros
              Corazones
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap12.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap12.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap12.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 13 - Las Enseñanzas de Dios y la Ley de Cristo</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap13.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap13.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap13.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 14 - La Semilla del Reino</td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap14.mobi')}
                >
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a
                  href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap14.epub')}
                >
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap14.pdf')}>
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td height="27">Cap. 15 - Cartas de Isaac Penington</td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap15..mobi">
                  kindle
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap15..epub">
                  epub
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://nyc3.digitaloceanspaces.com/flp-assets/zoe/publicaciones/isaacpenington/ip_vol2/IP_Vol2_Cap15.pdf">
                  pdf
                </a>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
